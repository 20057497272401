<template>
  <div
    v-show="show && haveControl"
    class="slider-content"
    :style="`margin-bottom: ${
      smartProgress.enabled ? smartProgress.height : 0
    }px`"
  >
    <v-card class="mx-4 mb-2" :color="_color" flat>
      <div class="d-flex align-center pa-0 pa-sm-1 pa-md-2">
        <v-btn
          v-if="settings.reproductionButton"
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          icon
          dark
          @click="handleReproduction()"
        >
          <v-icon>
            {{ state === "playing" ? "mdi-pause" : "mdi-play" }}
          </v-icon>
        </v-btn>

        <v-slider
          v-if="settings.progressBar"
          v-model="timer"
          track-color="grey"
          class="rounded-lg"
          thumb-color="white"
          :track-fill-color="settings.colors.primary"
          :max="totalTime"
          :min="0"
          hide-details
          @mouseup="seekTo()"
        />

        <v-spacer v-else />

        <span
          v-if="settings.timer"
          class="grey--text text--lighten-2 text-caption text-md-body-2 mx-2"
        >
          {{ _currentTimer }} / {{ _totalTimer }}
        </span>

        <v-hover v-if="settings.volume" v-slot="{ hover }">
          <div class="d-flex align-center">
            <v-btn
              class="mr-1"
              :small="$vuetify.breakpoint.smAndDown"
              icon
              dark
              @click="handleMute()"
            >
              <v-icon>
                {{ mute ? "mdi-volume-off" : "mdi-volume-high" }}
              </v-icon>
            </v-btn>

            <v-responsive v-show="hover" width="64px" max-width="64px">
              <v-slider
                v-model="volume"
                :track-fill-color="mute ? 'grey' : settings.colors.primary"
                track-color="grey"
                class="slider-no-thumb"
                thumb-color="white"
                :min="0"
                :max="100"
                hide-details
                @mouseup="setVolume()"
              />
            </v-responsive>
          </div>
        </v-hover>

        <v-btn
          v-if="settings.fullscreen"
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          icon
          dark
          @click="handleFullscreen()"
        >
          <v-icon>
            {{ fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
          </v-icon>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
const moment = require("moment");
moment.locale("pt-BR");

export default {
  data() {
    return {
      interval: null,
      timer: 0,
      lastSave: 0,
      volume: 75,
      mute: false,
      fullscreen: false,
    };
  },

  props: {
    show: {
      type: Boolean,
      default: true,
    },

    state: {
      type: String,
      default: "ended",
    },

    totalTime: {
      type: Number,
      default: 0,
    },

    tracking: {
      type: Boolean,
      default: false,
    },

    videoID: {
      type: String,
      default: "",
    },

    actionTimes: {
      type: Array,
      default: () => [],
    },

    smartProgress: {
      type: Object,
      default: () => ({
        enabled: false,
        color: "primary",
        height: 8,
      }),
    },

    haveControl: {
      type: Boolean,
      default: true,
    },

    autoPlayed: {
      type: Boolean,
      default: false,
    },

    endAutoPlay: {
      type: Number,
      default: 0,
    },

    settings: {
      type: Object,
      required: true,
    },
  },

  beforeDestroy() {
    if (this.interval) this.clearInterval();
  },

  watch: {
    ["timer"]() {
      if (this.autoPlayed) {
        if (this.endAutoPlay !== 0 && this.timer >= this.endAutoPlay)
          this.seekTo(0);

        return;
      }

      if (!this.actionTimes.length) return;

      const startIndex = this.actionTimes.findIndex(
        (el) => el.startTime === this.formatTimer(this.timer, false)
      );

      const endIndex = this.actionTimes.findIndex(
        (el) => el.endTime === this.formatTimer(this.timer, false)
      );

      if (startIndex >= 0 || endIndex >= 0) {
        const payload = {
          index: startIndex >= 0 ? startIndex : endIndex,
          show: endIndex < 0,
        };

        this.$emit("action-button", payload);
      }
    },
  },

  computed: {
    _currentTimer() {
      return this.formatTimer(this.timer);
    },

    _totalTimer() {
      return this.formatTimer(this.totalTime);
    },

    _color() {
      if (this.settings.progressBar) return this.settings.colors.secondary;

      return "transparent";
    },
  },

  methods: {
    handleReproduction() {
      this.$emit("change:reproduction");
    },

    handleTimeline(currentTime) {
      if (currentTime) this.timer = currentTime;

      this.interval = setInterval(() => {
        if (this.timer >= this.totalTime) return this.clearInterval();

        this.timer += 1000;

        // tracking
        if (
          this.timer - this.lastSave >= 5000 &&
          this.timer - this.lastSave < 6000
        )
          this.setTracking();
      }, 1000);
    },

    seekTo() {
      this.$emit("seek-to", this.timer);
    },

    handleFullscreen() {
      const iframe = document.getElementById("screen");

      if (!document.fullscreenElement) {
        if (iframe.requestFullscreen) iframe.requestFullscreen();
        else if (iframe.webkitRequestFullscreen)
          iframe.webkitRequestFullscreen(); // Para navegadores baseados em WebKit (Safari)
        else if (iframe.msRequestFullscreen) iframe.msRequestFullscreen(); // Para IE/Edge
        this.fullscreen = true;
        return;
      }

      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();

      this.fullscreen = false;

      /* const video = document.getElementById("screen");

       if (!document.fullscreenElement) {
        if (video.requestFullscreen) video.requestFullscreen();
        else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen();
        else if (video.msRequestFullscreen) video.msRequestFullscreen();
        this.fullscreen = true;
        return;
      }

      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();

      this.fullscreen = false; */
    },

    clearInterval(reset) {
      if (this.interval) clearInterval(this.interval);
      if (reset) this.timer = 0;
    },

    setTracking() {
      this.$emit("tracking", this.timer);
      this.lastSave = this.timer;

      if (this.tracking)
        localStorage.setItem(`MVSL-${this.videoID}`, String(this.timer));
    },

    setVolume() {
      if (!this.volume) this.mute = true;
      else if (this.volume && this.mute) this.mute = false;

      this.$emit("set-volume", this.volume);
    },

    handleMute() {
      this.mute = !this.mute;
      this.$emit("set-volume", this.mute ? 0 : this.volume);
    },

    formatTimer(data, slice = true) {
      const value = moment.utc(data).format("HH:mm:ss");

      if (value.startsWith("00:") && slice) return value.slice(3);
      return value;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
