<template>
  <v-responsive :aspect-ratio="16 / 10">
    <div class="d-flex flex-column fill-width fill-height">
      <div
        id="screen"
        class="d-flex fill-height align-center justify-center relative black rounded-lg"
        @mousemove="handleHover()"
      >
        <!-- player -->
        <YouTube
          ref="youtube"
          :videoID="videoID"
          :startIn="
            _autoPlay ? reproduction.startAutoPlay : reproduction.startIn
          "
          :autoPlayed="autoPlayed"
          :started="started"
          :settings="settings"
          @ready="onPlayerReady($event)"
          @state-change="onPlayerStateChange($event)"
        />

        <!-- overlay -->
        <Overlay
          :state="reproduction.state"
          :thumbnails="options.thumbnails"
          :autoPlay="options.autoPlay"
          :resumePlay="options.resumePlay"
          :settings="settings"
          :restartOption="!!reproduction.startIn"
          :autoPlayed="autoPlayed"
          :resumed="resumed"
          :started="started"
          :haveControl="_haveControl"
          :showControls="showControls"
          :loading="loading"
          @change:reproduction="
            handleReproduction();
            handleHover();
          "
          @restart="
            handleRestart();
            handleHover();
          "
        />

        <!-- control bar -->
        <ControlBar
          v-if="!loading"
          ref="controlBar"
          :show="showControls"
          :state="reproduction.state"
          :totalTime="reproduction.totalTime"
          :tracking="options.resumePlay.enabled && !autoPlayed"
          :videoID="videoID"
          :actionTimes="options.actionButtons"
          :smartProgress="options.smartProgress"
          :haveControl="_haveControl"
          :autoPlayed="autoPlayed"
          :endAutoPlay="reproduction.endAutoPlay"
          :settings="settings"
          @change:reproduction="handleReproduction()"
          @seek-to="seekTo($event)"
          @set-volume="setVolume($event)"
          @action-button="handleActionBttn($event)"
          @tracking="sendPaused($event)"
        />

        <!-- smart progress -->
        <SmartProgress
          ref="smartProgress"
          :smartProgress="options.smartProgress"
          :totalTime="reproduction.totalTime"
          :state="reproduction.state"
          :color="settings.colors.primary"
        />

        <!-- action buttons -->
        <v-btn
          v-for="(item, i) in _insideActionButtons"
          class="ma-14 action-bttn"
          target="_parent"
          :color="item.color"
          :top="item.position.includes('top')"
          :right="item.position.includes('right')"
          :bottom="item.position.includes('bottom')"
          :left="item.position.includes('left')"
          :small="item.size === 'small'"
          :x-large="item.size === 'large'"
          :href="item.redirectURL"
          :key="i"
          absolute
        >
          <span :style="`color: ${item.textColor}`">
            {{ item.text }}
          </span>
        </v-btn>
      </div>

      <div class="bot-action-area">
        <div v-if="_outsideActionButtons.length">
          <v-btn
            v-for="(item, i) in _outsideActionButtons"
            class="action-bttn"
            target="_parent"
            :color="item.color"
            :key="i"
            :href="item.redirectURL"
            :small="item.size === 'small'"
            :x-large="item.size === 'large'"
          >
            <span :style="`color: ${item.textColor}`">
              {{ item.text }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>
  </v-responsive>
</template>

<script>
import { request } from "@/services";
import YouTube from "./partials/youTube/YouTube";
import ControlBar from "./partials/controlBar/ControlBar";
import Overlay from "./partials/overlay/Overlay";
import SmartProgress from "./partials/smartProgress/SmartProgress";

const moment = require("moment");
moment.locale("pt-BR");

export default {
  name: "Player",

  data() {
    return {
      loading: true,
      interval: null,
      started: false,
      autoPlayed: false,
      resumed: false,
      showControls: false,
      playID: null,
      reproduction: {
        state: "unstarted",
        startIn: 0,
        startAutoPlay: 0,
        endAutoPlay: 0,
        totalTime: 0,
      },
      options: {},
    };
  },

  props: {
    videoID: {
      type: String,
      required: true,
    },

    videoToken: {
      type: String,
      required: true,
    },

    userToken: {
      type: String,
      required: true,
    },

    settings: {
      type: Object,
      required: true,
    },

    videoOptions: {
      type: Object,
      required: true,
    },

    stopTracking: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    YouTube,
    ControlBar,
    Overlay,
    SmartProgress,
  },

  created() {
    this.options = this.videoOptions;

    if (this.options.autoPlay.enabled && !this.options.autoPlay.gifURL) {
      const duration = moment.duration(this.options.autoPlay.startAt);
      const durationFinish = moment.duration(this.options.autoPlay.endAt);

      this.reproduction.startAutoPlay = duration.asMilliseconds();
      this.reproduction.endAutoPlay = durationFinish.asMilliseconds();
    }

    if (this.options.backgroundPlayback && !this.options.autoPlay.gifURL) {
      const duration = moment.duration("00:00:00");
      const durationFinish = moment.duration(this.settings.videoTime);

      this.reproduction.startAutoPlay = duration.asMilliseconds();
      this.reproduction.endAutoPlay = durationFinish.asMilliseconds();
    }

    if (this.options.resumePlay.enabled) {
      const tracking = localStorage.getItem(`MVSL-${this.videoID}`);
      if (tracking) {
        this.reproduction.startIn = Number(tracking);
        this.resumed = true;
      }
    }
  },

  beforeDestroy() {
    if (this.interval) clearTimeout(this.interval);
  },

  watch: {
    videoOptions: {
      handler() {
        this.options = this.videoOptions;
      },
      deep: true,
    },
  },

  computed: {
    _insideActionButtons() {
      return this.options.actionButtons.filter(
        (el) => el.position !== "outside" && el.show
      );
    },

    _outsideActionButtons() {
      return this.options.actionButtons.filter(
        (el) => el.position === "outside" && el.show
      );
    },

    _autoPlay() {
      return (
        (this.options.autoPlay.enabled || this.options.backgroundPlayback) &&
        !this.options.autoPlay.gifURL
      );
    },

    _haveControl() {
      const states = ["unstarted", "ended", "paused"];

      return (
        (this.settings.reproductionButton ||
          this.settings.progressBar ||
          this.settings.timer ||
          this.settings.volume ||
          this.settings.fullscreen) &&
        !states.includes(this.reproduction.state)
      );
    },
  },

  methods: {
    handleReproduction() {
      if (this.autoPlayed) {
        this.seekTo(this.reproduction.startIn);
        this.setVolume(75);
        this.autoPlayed = false;

        return;
      }

      if (this.reproduction.state === "playing")
        this.$refs.youtube.pauseVideo();
      else this.$refs.youtube.playVideo();
    },

    onPlayerReady(event) {
      this.reproduction.totalTime = event;
      this.loading = false;

      if (this._autoPlay) {
        this.autoPlayed = true;

        this.setVolume(0);
        this.$refs.youtube.playVideo();
      }

      this.handleHover();
    },

    onPlayerStateChange({ event, currentTime }) {
      if (event === "ended") {
        if (this.autoPlayed && !this.started) {
          // restart video on finish with autoplay enabled
          this.seekTo(this.reproduction.startAutoPlay);
          this.$refs.youtube.playVideo();
        } else {
          // handle ended video
          if (this.options.smartProgress.enabled)
            this.$refs.smartProgress.clearInterval(true);

          this.$refs.controlBar.clearInterval(true);
          localStorage.removeItem(`MVSL-${this.videoID}`);

          this.sendPaused(currentTime);
        }
      } else if (event === "playing") {
        // handle playing video
        this.sendPlayed();
        this.handleHover();

        if (this.options.smartProgress.enabled)
          this.$refs.smartProgress.handleTimeline(currentTime);

        this.$refs.controlBar.handleTimeline(currentTime);

        if (!this.started && !this.autoPlayed) this.started = true;
      } else if (event === "paused" || event === "buffering") {
        // handle paused or when change time of video
        if (this.options.smartProgress.enabled)
          this.$refs.smartProgress.clearInterval();

        this.$refs.controlBar.setTracking();
        this.$refs.controlBar.clearInterval();
      }

      if (event !== "bufferind") {
        this.reproduction.state = event;
      }
    },

    handleRestart() {
      if (this.autoPlayed) this.autoPlayed = false;

      localStorage.setItem(`MVSL-${this.videoID}`, String(0));

      this.setVolume(75);
      this.seekTo(0);
      this.$refs.youtube.playVideo();
    },

    seekTo(event) {
      this.$refs.youtube.seekTo(event);
    },

    handleActionBttn(event) {
      this.options.actionButtons[event.index].show = event.show;
    },

    setVolume(event) {
      this.$refs.youtube.setVolume(event);
    },

    handleHover() {
      if (this.loading || this.autoPlayed) return;

      this.showControls = true;

      if (this.interval) clearTimeout(this.interval);

      this.interval = setTimeout(() => {
        this.showControls = false;
      }, 4000);
    },

    async sendPlayed() {
      if (this.autoPlayed || this.playID || this.stopTracking) return;

      const payload = {
        method: "playedVideo",
        videoToken: this.videoToken,
        userToken: this.userToken,
        videoHash: this.$route.query.hash,
      };

      await request(payload).then(({ data }) => {
        this.playID = structuredClone(data.playID);
      });
    },

    async sendPaused(timer) {
      if (this.autoPlayed || !this.playID || this.stopTracking) return;

      const duration = moment.duration(timer);

      const payload = {
        method: "pausedVideo",
        videoToken: this.videoToken,
        userToken: this.userToken,
        videoHash: this.$route.query.hash,
        time: moment.utc(duration.as("milliseconds")).format("HH:mm:ss"),
        playID: this.playID,
      };

      await request(payload);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
