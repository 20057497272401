<template>
  <div
    ref="player"
    id="player"
    class="player"
    :style="{
      ...(settings.border.show && {
        'border-width': settings.border.size + 'px',
        'border-color': settings.border.color,
        'border-radius': settings.border.rounded ? '0.5rem' : '0rem',
        'border-style': 'solid',
      }),
    }"
  />
</template>

<script>
export default {
  data() {
    return {
      player: null,
    };
  },

  props: {
    videoID: {
      type: String,
      default: "",
    },

    startIn: {
      type: Number,
      default: 0,
    },

    started: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.renderPlayer();
  },

  methods: {
    renderPlayer() {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = this.initPlayer;
    },

    initPlayer() {
      this.player = new window.YT.Player(this.$refs.player, {
        videoId: this.videoID,
        playerVars: {
          enablejsapi: 1,
          html5: 1,
          autoplay: 0,
          controls: 0,
          modestbranding: 1,
          showinfo: 0,
          iv_load_policy: 3,
          showtitle: false,
          disablekb: 1,
          rel: 0,
          fs: 0,
          volume: 75,
          start: this.startIn >= 5000 ? (this.startIn / 1000).toFixed(0) : 0,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
          onError: this.onPlayerError,
        },
      });
    },

    onPlayerReady() {
      this.$emit("ready", this.player.getDuration() * 1000);
    },

    onPlayerStateChange(event) {
      const events = {
        "-1": "unstarted",
        0: "ended",
        1: "playing",
        2: "paused",
        3: "buffering",
        5: "cued",
      };

      this.$emit("state-change", {
        event: events[String(event.data)],
        currentTime: this.getCurrentTime(),
      });
    },

    onPlayerError() {
      this.$emit("error");
    },

    playVideo() {
      if (!this.started && this.startIn < 5000) this.seekTo(0);

      this.player.playVideo();
    },

    pauseVideo() {
      this.player.pauseVideo();
    },

    seekTo(event) {
      this.player.seekTo(event / 1000);
    },

    getCurrentTime() {
      return this.player.getCurrentTime() * 1000;
    },

    setVolume(value) {
      this.player.setVolume(value);
    },

    setQuality(value) {
      this.player.setPlaybackQuality(value);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
